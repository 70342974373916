const ID_TOKEN_KEY = "id_token" as string;
const ID_BREAK_DATE = "break_date" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getBreakDate = (): string | null => {
  return window.localStorage.getItem(ID_BREAK_DATE);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveBreakDate = (): void => {
  const now = new Date();
  const breakdate = now.setHours(now.getHours() + 6); //now.setMinutes(now.getMinutes() + 2);
  window.localStorage.setItem(ID_BREAK_DATE, breakdate.toString());
};

export const saveNowBreak = (): void => {
  const now = new Date();
  const breakdate = now.setHours(now.getHours() + 0);
  window.localStorage.setItem(ID_BREAK_DATE, breakdate.toString());
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  try {
    window.localStorage.removeItem(ID_TOKEN_KEY);
  } catch (e) {
    console.log("remove ID");
  }
  try {
    window.localStorage.removeItem(ID_BREAK_DATE);
  } catch (e) {
    console.log("remove DATE");
  }
};

export default {
  getToken,
  saveToken,
  destroyToken,
  getBreakDate,
  saveBreakDate,
  saveNowBreak,
};
