import { ref } from "vue";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Paging {
  ShowPage: [number];
  currentPage: number;
  totalPages: number;
  rowsPerPage: number;
  pagesPerBundle: number;
}

export interface PagingInfo {
  paging: Paging;
  totalItems: number;
}

@Module
export default class PageModule extends VuexModule implements PagingInfo {
  totalItems = 0;
  paging = {
    ShowPage: [1],
    currentPage: 1,
    totalPages: 1,
    rowsPerPage: 10,
    pagesPerBundle: 5,
  } as Paging;

  get pageInfo(): Paging {
    return this.paging;
  }

  get totalCnt() {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_TOTAL](total) {
    this.totalItems = total;
  }

  @Mutation
  [Mutations.SET_PAGE](paging) {
    this.paging = paging;
  }

  @Mutation
  [Mutations.SET_GENERATE_PAGE](pagingInfo) {
    const total = pagingInfo.totalItems;
    const paginationC = pagingInfo.paging;
    const rowsPP = paginationC.rowsPerPage;
    const minShowPage = ref(1);
    const maxShowPage = ref(1);
    if (total <= rowsPP) {
      paginationC.totalPages = 1;
      paginationC.currentPage = 1;
    } else {
      // console.log(paginationC.currentPage);
      const pagesPB = paginationC.pagesPerBundle;
      const totalPCnt =
        (total - (total % rowsPP)) / rowsPP + (total % rowsPP > 0 ? 1 : 0);
      paginationC.totalPages = totalPCnt;
      const totalBCnt =
        (totalPCnt - (totalPCnt % pagesPB)) / pagesPB +
        (totalPCnt % pagesPB > 0 ? 1 : 0);
      const currP = paginationC.currentPage;

      for (let i = 0; i < totalBCnt; i++) {
        const minSP = i * pagesPB + 1;
        const maxSP = (i + 1) * pagesPB;
        if (minSP <= currP && maxSP >= currP) {
          minShowPage.value = minSP;
          maxShowPage.value = maxSP > totalPCnt ? totalPCnt : maxSP;
        }
      }
    }
    const ShowPage = Array.from(
      { length: maxShowPage.value - minShowPage.value + 1 },
      (value, index) => minShowPage.value + index
    );
    paginationC.ShowPage = ShowPage;
    //this.context.commit(Mutations.SET_PAGE, paginationC as Paging);
    this.paging = paginationC;
  }

  @Action
  [Actions.GO_PAGE](current) {
    const targetPaging = this.paging;
    targetPaging.currentPage = current;
    const pagingInfo = {
      totalItems: this.totalItems,
      paging: targetPaging,
    } as PagingInfo;
    this.context.commit(Mutations.SET_GENERATE_PAGE, pagingInfo as PagingInfo);
  }

  @Action
  [Actions.GO_PREV_PAGE](p) {
    const targetPaging = this.paging;
    if (p == 1) {
      targetPaging.currentPage = 1;
    } else {
      const prev = targetPaging.currentPage - 1;
      targetPaging.currentPage = prev < 1 ? 1 : prev;
    }
    const pagingInfo = {
      totalItems: this.totalItems,
      paging: targetPaging,
    } as PagingInfo;
    this.context.commit(Mutations.SET_GENERATE_PAGE, pagingInfo as PagingInfo);
  }

  @Action
  [Actions.GO_NEXT_PAGE](n) {
    const targetPaging = this.paging;
    if (n == 1) {
      targetPaging.currentPage = targetPaging.totalPages;
    } else {
      const next = targetPaging.currentPage + 1;
      targetPaging.currentPage =
        next > targetPaging.totalPages ? targetPaging.totalPages : next;
    }
    const pagingInfo = {
      totalItems: this.totalItems,
      paging: targetPaging,
    } as PagingInfo;
    this.context.commit(Mutations.SET_GENERATE_PAGE, pagingInfo as PagingInfo);
  }
}
