import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  seq: number;
  name: string;
  surname: string;
  email: string;
  level: number;
  password: string;
  api_token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = false;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }
  get isToken(): boolean {
    return this.isAuthenticated;
  }

  get isDateAuthenticated(): boolean {
    const breakDate = JwtService.getBreakDate();
    const now = new Date().getTime().toString();
    const validDate = breakDate != null && now < breakDate;
    //console.log(now + " < " + breakDate);
    return validDate;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
    JwtService.saveToken(user.api_token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
    JwtService.saveNowBreak();
  }

  // @Action
  // [Actions.LOGIN](credentials) {
  //   return ApiService.post("login", credentials)
  //     .then(({ data }) => {
  //       this.context.commit(Mutations.SET_AUTH, data);
  //       JwtService.saveBreakDate();
  //     })
  //     .catch(({ response }) => {
  //       this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //     });
  // }
  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("/api/v1/auth/loginProcess", credentials) //.post("login", credentials)
      .then(({ data }) => {
        if (data.code == "OK") {
          const successloginInfo = {
            seq: data.data.seq,
            name: data.data.firstName,
            surname: data.data.lastName,
            email: data.data.email,
            level: data.data.level,
            api_token: data.data.email,
          };
          if (data.data.level < 11) {
            const failmessage = {
              email: ["접근 권한이 없는 사용자입니다."],
            };
            this.context.commit(Mutations.SET_ERROR, failmessage);
          } else {
            this.context.commit(Mutations.SET_AUTH, successloginInfo);
            JwtService.saveBreakDate();
          }
        } else {
          const failmessage = {
            email: ["로그인 정보가 바르지 않습니다."],
          };
          this.context.commit(Mutations.SET_ERROR, failmessage);
        }
      })
      .catch(({ response }) => {
        if (response.status != 200) {
          const errormessage = {
            email: [
              "시스템 오류가 발생했습니다. 관리자에게 문의하시기 바랍니다.",
            ],
          };
          this.context.commit(Mutations.SET_ERROR, errormessage);
        }
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
    ApiService.get("/api/v1/auth/logout");
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get("/api/v1/member/admin/getProfile")
        .then(({ data }) => {
          if (data.code == "OK") {
            const verifyInfo = {
              seq: data.data.seq,
              name: data.data.firstName,
              surname: data.data.lastName,
              email: data.data.email,
              level: data.data.level,
              api_token: data.data.email,
            };
            if (data.data.email === undefined) {
              this.context.commit(Mutations.PURGE_AUTH);
            } else {
              this.context.commit(Mutations.SET_AUTH, verifyInfo);
            }
          } else {
            this.context.commit(Mutations.PURGE_AUTH);
          }
        })
        .catch(({ response }) => {
          if (response.status != 200) {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
            this.context.commit(Mutations.PURGE_AUTH);
          }
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
