import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Company } from "@/core/data/company";

export interface CompanyInfo {
  errors: unknown;
  company: Company;
}

@Module
export default class CompanyModule extends VuexModule implements CompanyInfo {
  errors = {};
  company = {} as Company;
  selectSeq = 0;

  get selectedCompanySeq() {
    return this.selectSeq;
  }

  /**
   * Get user Information object
   * @returns User
   */
  get companyInfo(): Company {
    return this.company;
  }

  get companyInfoForm() {
    const company = this.company;
    console.log(company);
    const companyFormData = ref({
      company_name: "111",
      addr1: "",
      addr2: "",
      city: "",
      stats: "",
      zip: "",
      logo: "",
      contact_0: "",
      contact_1: "",
      contact_2: "",
      contact_3: "",
      company_memo: "",
    });
    for (const item in company) {
      if (company[item]) {
        if (item === "tel") {
          const tel_s = ("" + company[item]).split("-");
          companyFormData.value.contact_0 = tel_s[0];
          companyFormData.value.contact_1 = tel_s[1];
          companyFormData.value.contact_2 = tel_s[2];
          companyFormData.value.contact_3 = tel_s[3];
        } else {
          companyFormData.value[item] = company[item];
        }
      }
    }
    console.log(companyFormData);

    return companyFormData;
  }

  /**
   * Get company processing errors
   * @returns array
   */
  get getCompanyProcErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_COMPANY](company) {
    this.company = company;
  }

  @Mutation
  [Mutations.SET_COMPANY_SELECT](selectSeq) {
    this.selectSeq = selectSeq;
  }

  @Action
  [Actions.GET_COMPANY](payload) {
    ApiService.get("/api/v1/company/get", payload)
      .then(({ data }) => {
        if (data.code === "OK") {
          console.log(data.data);
          const company = { ...data.data } as Company;
          this.context.commit(Mutations.SET_MEMBER, company);
        } else {
          this.context.commit(Mutations.SET_MEMBER, {} as Company);
        }
      })
      .catch(() => {
        //if (response.status != 200) {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        this.context.commit(Mutations.SET_MEMBER, {} as Company);
        //}
      });
  }

  // @Action
  // [Actions.UPDATE_COMPANY](payload) {
  //   return ApiService.post("/api/v1/company/modify", payload)
  //     .then(({ data }) => {
  //       console.log(data);
  //       if (data.code !== "OK") {
  //         console.log(data.code);
  //         const errormessage = {
  //           email: ["Update failed."],
  //         };
  //         this.context.commit(Mutations.SET_ERROR, errormessage);
  //       }
  //     })
  //     .catch(({ response }) => {
  //       this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //     });
  // }
}
