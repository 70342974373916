import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Member {
  birth: string; //"2022-10-04T08:14:23.679Z",
  country: string;
  email: string;
  firstName: string;
  hitConnect: number;
  lastName: string;
  level: number;
  nickName: string;
  part: string;
  phone: string;
  position: string;
  state: string;
  tel: string;
  password: string;
  seq: number;
  companyId: number;
  company: {
    seq: number;
    country: string;
    name: string;
    zipcode: string;
    addr: string;
    addr2: string;
    city: string;
    state: string;
    tel: string;
    preSeq: number;
    status: string;
    idno: string;
    fax: string;
    url: string;
    logoFile: string;
    logoFileTmp: string;
    register: number;
    registMember: string;
  };
  zipCode: string;
  addr: string;
  // managerName: string;
  // managerPhone: string;
  // managerEmail: string;
  // preSeq: number;
}

export interface MemberInfo {
  errors: unknown;
  member: Member;
}

@Module
export default class MemberModule extends VuexModule implements MemberInfo {
  errors = {};
  member = {} as Member;
  selectSeq = 0;

  get selectedMemberSeq() {
    return this.selectSeq;
  }

  /**
   * Get user Information object
   * @returns User
   */
  get memberInfo(): Member {
    return this.member;
  }

  get memberInfoForm() {
    const profile = this.member;
    const profileFormData = ref({
      firstName: "",
      lastName: "",
      birth_year: "",
      birth_month: "",
      birth_day: "",
      tel_0: "",
      tel_1: "",
      tel_2: "",
      tel_3: "",
      hp_1: "",
      hp_2: "",
      hp_3: "",
      email: "",
      company: {
        seq: 0,
        name: "",
      },
      password: "",
      password_confirmation: "",
    });

    for (const item in profile) {
      if (profile[item]) {
        if (item === "birth") {
          const date = new Date(profile[item]);
          const newdate = new Date(
            date.getTime() - new Date().getTimezoneOffset() * 60 * 1000
          );
          // console.log(newdate.toISOString());
          const [year, month, day] = newdate
            .toISOString()
            .substring(0, 10)
            .split("-");
          // const birth_arr = ("" + profile[item]).split("T");
          // const [year, month, day] = birth_arr[0].split("-");
          profileFormData.value.birth_year = "" + parseInt(year);
          profileFormData.value.birth_month = "" + parseInt(month);
          profileFormData.value.birth_day = "" + parseInt(day);
        } else if (item === "tel") {
          const tel_s = ("" + profile[item]).split("-");
          profileFormData.value.tel_0 = tel_s[0];
          profileFormData.value.tel_1 = tel_s[1];
          profileFormData.value.tel_2 = tel_s[2];
          profileFormData.value.tel_3 = tel_s[3];
        } else if (item === "phone") {
          const hp_s = ("" + profile[item]).split("-");
          profileFormData.value.hp_1 = hp_s[0];
          profileFormData.value.hp_2 = hp_s[1];
          profileFormData.value.hp_3 = hp_s[2];
        } else {
          profileFormData.value[item] = profile[item];
        }
      }
    }
    return profileFormData.value;
  }

  /**
   * Get member processing errors
   * @returns array
   */
  get getMemberProcErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_MEMBER](member) {
    this.member = member;
  }

  @Mutation
  [Mutations.SET_MEMBER_SELECT](selectSeq) {
    this.selectSeq = selectSeq;
  }

  @Action
  [Actions.GET_PROFILE]() {
    ApiService.get("/api/v1/member/admin/getProfile")
      .then(({ data }) => {
        if (data.code === "OK") {
          const member = { ...data.data } as Member;
          this.context.commit(Mutations.SET_MEMBER, member);
        } else {
          this.context.commit(Mutations.SET_MEMBER, {} as Member);
        }
      })
      .catch(() => {
        //if (response.status != 200) {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        this.context.commit(Mutations.SET_MEMBER, {} as Member);
        //}
      });
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    return ApiService.post("/api/v1/member/modify", payload)
      .then(({ data }) => {
        console.log(data);
        if (data.code !== "OK") {
          console.log(data.code);
          const errormessage = {
            email: ["Update failed."],
          };
          this.context.commit(Mutations.SET_ERROR, errormessage);
        }
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
